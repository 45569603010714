import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="ansokan">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Daniel Storm" />
         </div>
         <div className="nine columns main-col">
            <h2>Välkommen att skicka din ansökan</h2>

            <p>Skicka din ansökan till mig eller hör av dig om du har några frågor om oss :)</p>

            <div className="row">
               <div className="columns contact-details">
                  <h2>Daniel Storm</h2>
                  <p className="address">
						   <span>Företagsledare/VD</span><br />
						   <span>070-517 90 60</span><br />
                     <span><a href="mailto:daniel@rasbergekonomi.se">daniel@rasbergekonomi.se</a></span>
					   </p>
               </div>
               <div className="columns download">
                  <p>
                     <a href="mailto:daniel@rasbergekonomi.se?subject=Ansökan till Rasberg ekonomi" className="button"><i className="fa fa-envelope"></i>Skicka ansökan</a>
                  </p>
               </div>
            </div>
            
         </div>
      </div>

   </section>
    );
  }
}

export default About;
