import React, { Component } from 'react';

class Header extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var occupation= this.props.data.occupation;
      var description= this.props.data.description;
      var city= this.props.data.address.city;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li><a href="https://www.rasbergekonomi.se">Till rasbergekonomi.se</a></li>
	         <li><a className="smoothscroll" href="#om-tjansten">Om oss</a></li>
            <li><a className="smoothscroll" href="#ansokan">Skicka ansökan</a></li>
            {/* <li><a className="smoothscroll" href="#portfolio">Works</a></li>
            <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
            <li><a className="smoothscroll" href="#contact">Contact</a></li> */}
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h3>Uppdaterad 2022-09-22</h3>
            <h1 className="responsive-headline">Är du vår nya kollega?</h1>
            <h3>
               Just nu har vi inga lediga tjänster men vi tar gärna emot din spontanansökan.
               Skicka några personliga rader om dig tillsammans med ditt CV, kanske dyker det upp ett jobb snart som passar dig!
            </h3>
            {/* <ul className="social">
               {networks}
            </ul> */}
            <hr />
            <a href="mailto:daniel@rasbergekonomi.se?subject=Ansökan till Rasberg ekonomi" className="button"><i className="fa fa-envelope"></i>Skicka ansökan</a>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#om-tjansten"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
