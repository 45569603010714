import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p>{work.description}</p>
        </div>
      })
      var skills = this.props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (
      <section id="om-tjansten">

      {/* <div className="row education">
         <div className="three columns header-col">
            <h1><span>Beskrivning</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                <p>Vi söker dig som arbetat med löpande redovisning samt självständigt gjort bokslut och deklarationer under några år.</p>
                <p>Har du dessutom erfarenhet inom lön och rådgivning sitter det inte i vägen!</p>
                <p>Tjänsten är på heltid och vårt kontor ligger i Västhaga i Örebro. Vi arbetar på plats på kontoret (inte ute hos kund) med möjlighet till hemarbete vid behov och praktisk möjlighet.</p>
               </div>
            </div>
         </div>
      </div> */}


      



      <div className="row work">

         <div className="three columns header-col">
            <h1><span>Om oss</span></h1>
         </div>

         <div className="nine columns main-col">

            <p>Vi är ett familjeföretag som drivs i andra generationen. En lagom stor redovisningsbyrå, inte för stor och inte för liten vilket är bra både för våra kunder och för oss som jobbar här. Vi är idag sju stycken anställda varav fem arbetar som konsulter. Här finns möjlighet att lära av varandra och att lösa problem tillsammans, men vi har även stor möjlighet att genomföra förändringar då vi inte är för stor organisation.</p>
            
            <p>Att trivas här är viktigt för oss och vi jobbar aktivt med förbättringsarbete inom alla områden. Det är ett synsätt vi vill ha, att kontinuerligt utvärdera och inte nöja oss, och vi ser gärna att du delar den bilden med oss.</p>

            {/* <div className="bars">
              <ul className="skills">
                {skills}
              </ul>
            </div> */}
          </div>
      </div>
      <div className="row work">

        <div className="three columns header-col">
          <h1><span>Förmåner</span></h1>
        </div>

        <div className="nine columns main-col">
          <p>Hos oss har du bland annat 30 dagars semester, kortare arbetsdagar under sommaren och 2 500 kr i friskvårdsbidrag.</p>
        </div>

      </div>

      <div className="row">
        <div className="three columns header-col">
          <h1><span>Om dig</span></h1>
        </div>

        <div className="nine columns main-col">
          <p>Ja, det här är ju svårt för oss att säga så mycket om. Vi hoppas att du är snäll och att du är intresserad av att bidra till att andra runt dig mår och presterar bra. I övrigt får du fylla i, vi är nyfikna och vi lyssnar!</p>
        </div>
      </div>

   </section>
    );
  }
}

export default Resume;
